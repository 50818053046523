import React from "react";
import ExxonLogo from "../../assets/ImagesWebp/CalenderExxon.webp";
import { InlineWidget } from "react-calendly";
import ExxonLeftLogo from "../../assets/ImagesWebp/ExxonLeft.webp";
import ContactForm from "../PopUp/ContactForm";
import { Helmet } from "react-helmet";
// import ContactForm from "../PopUp/ContactForm";

const YourPageComponent = () => {
  return (
    <>
      <Helmet>
        <title>Exxon Info || Shcedule Call</title>
        <link rel="canonical" href="https://exxoninfo.com/shcedulecall" />
      </Helmet>
      <div className="page-content" style={{ paddingTop: "8rem" }}>
        <div className="container">
          <div className="row">
            <div className="col-lg-6">
              <div className="Left-Exxon-head">
                <img
                  src={ExxonLogo}
                  alt="ExxonLogo"
                  className="calnder-left-logo"
                />
                <p></p>

                <div className="Exxon-Business-Head">
                  <img
                    src={ExxonLeftLogo}
                    alt="ExxonLeftLogo"
                    className="Business-Head-logo"
                  />
                  <p className="Icon-SubHead">Business Development Head</p>
                  <h4 className="Exxon-30M">30 Minute Meeting</h4>
                </div>
              </div>
            </div>

            <div className="col-lg-6">
              <div className="Exxon-right-Calnder">
                <InlineWidget url="https://calendly.com/exxoninfomkt/collaboration-on-it-staffing-needs?back=1&month=2024-03" />
              </div>
            </div>
          </div>
        </div>
      </div>
      <ContactForm></ContactForm>
    </>
  );
};

export default YourPageComponent;

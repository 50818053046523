import React from "react";
import "./Technology.css";
import Slider from "react-slick";
import nodejslogo from "../../assets/ImagesWebp/nodejs.webp";
import ioslogo from "../../assets/ImagesWebp/ios.webp";
import angularlogo from "../../assets/ImagesWebp/angular.webp";
import reactNative from "../../assets/ImagesWebp/react native.webp";
import dotnetLogo from "../../assets/ImagesWebp/net.webp";
import androidLogo from "../../assets/ImagesWebp/android.webp";
import csslogo from "../../assets/ImagesWebp/css.webp";
import htmllogo from "../../assets/ImagesWebp/html.webp";
import javaLogo from "../../assets/ImagesWebp/java.webp";
import flutterLogo from "../../assets/ImagesWebp/flutter.webp";
import mernstackLogo from "../../assets/ImagesWebp/mern.webp";
import meanstackLogo from "../../assets/ImagesWebp/mean.webp";
import one from "../../assets/ImagesWebp/1.webp";
import two from "../../assets/ImagesWebp/2.webp";
import three from "../../assets/ImagesWebp/3.webp";
import four from "../../assets/ImagesWebp/4.webp";
import five from "../../assets/ImagesWebp/5.webp";
import six from "../../assets/ImagesWebp/6.webp";
import seven from "../../assets/ImagesWebp/7.webp";
import eight from "../../assets/ImagesWebp/8.webp";
import nine from "../../assets/ImagesWebp/9.webp";
import ten from "../../assets/ImagesWebp/10.webp";
import eleven from "../../assets/ImagesWebp/11.webp";

const Technology = () => {
  var settings = {
    dots: false,
    slidesToShow: 4,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 2000,
    pauseOnHover: false,
    infinite: true,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 4,
          slidesToScroll: 3,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          initialSlide: 2,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  return (
    <>
      <section className="TechnologySection">
        <div
          className="container"
          style={{ left: "0", border: "none", backgroundColor: "#ffffff" }}
        >
          <h2 className="text-center technologysecion-head">
            Technologies We Use
          </h2>
          <div className="Exxon-header-border"></div>
          <div className="Exxon-Tech-Main">
            <Slider {...settings} style={{ marginLeft: "140px" }}>
              <div className="swipper-slider">
                <a className="">
                  <img src={nodejslogo} className="ExxonImg" alt="Nodejslogo" />
                </a>
              </div>
              <div className="swipper-slider">
                <a className="">
                  <img src={ioslogo} className="ExxonImg" alt="ioslogo" />
                </a>
              </div>

              <div className="swipper-slider">
                <a className="">
                  <img src={angularlogo} className="ExxonImg" alt="angular" />
                </a>
              </div>

              <div className="swipper-slider">
                <a className="">
                  <img
                    src={reactNative}
                    className="ExxonImg"
                    alt="reactNative"
                  />
                </a>
              </div>

              <div className="swipper-slider">
                <a className="">
                  <img src={dotnetLogo} className="ExxonImg" alt="client5" />
                </a>
              </div>

              <div className="swipper-slider">
                <a className="">
                  <img
                    src={androidLogo}
                    className="ExxonImg"
                    alt="androidLogo"
                  />
                </a>
              </div>
              <div className="swipper-slider">
                <a className="">
                  <img src={csslogo} className="ExxonImg" alt="csslogo" />
                </a>
              </div>
              <div className="swipper-slider">
                <a className="">
                  <img src={htmllogo} className="ExxonImg" alt="htmllogo" />
                </a>
              </div>

              <div className="swipper-slider">
                <a className="">
                  <img src={javaLogo} className="ExxonImg" alt="javaLogo" />
                </a>
              </div>

              <div className="swipper-slider">
                <a className="">
                  <img
                    src={flutterLogo}
                    className="ExxonImg"
                    alt="flutterLogo"
                  />
                </a>
              </div>

              <div className="swipper-slider">
                <a className="">
                  <img
                    src={mernstackLogo}
                    className="ExxonImg"
                    alt="mernstack"
                  />
                </a>
              </div>

              <div className="swipper-slider">
                <a className="">
                  <img
                    src={meanstackLogo}
                    className="ExxonImg"
                    alt="client12"
                  />
                </a>
              </div>

              <div className="swipper-slider">
                <a className="">
                  <img src={one} className="ExxonImg" alt="one" />
                </a>
              </div>

              <div className="swipper-slider">
                <a className="">
                  <img src={two} className="ExxonImg" alt="two" />
                </a>
              </div>

              <div className="swipper-slider">
                <a className="">
                  <img src={three} className="ExxonImg" alt="three" />
                </a>
              </div>

              <div className="swipper-slider">
                <a className="">
                  <img src={four} className="ExxonImg" alt="four" />
                </a>
              </div>

              <div className="swipper-slider">
                <a className="">
                  <img src={five} className="ExxonImg" alt="five" />
                </a>
              </div>

              <div className="swipper-slider">
                <a className="">
                  <img src={six} className="ExxonImg" alt="six" />
                </a>
              </div>

              <div className="swipper-slider">
                <a className="">
                  <img src={seven} className="ExxonImg" alt="seven" />
                </a>
              </div>

              <div className="swipper-slider">
                <a className="">
                  <img src={eight} className="ExxonImg" alt="eight" />
                </a>
              </div>

              <div className="swipper-slider">
                <a className="">
                  <img src={nine} className="ExxonImg" alt="nine" />
                </a>
              </div>

              <div className="swipper-slider">
                <a className="">
                  <img src={ten} className="ExxonImg" alt="ten" />
                </a>
              </div>

              <div className="swipper-slider">
                <a className="">
                  <img src={eleven} className="ExxonImg" alt="eleven" />
                </a>
              </div>
            </Slider>
          </div>
        </div>
      </section>
    </>
  );
};

export default Technology;

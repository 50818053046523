import React, { useState, useEffect } from "react";
import "./ChatBot.css";
import ExxonIconc from "../../assets/Images/ExxonIconchatbot.png";
import { addDoc, collection } from "firebase/firestore/lite";
import { getFirestore } from "firebase/firestore/lite";
import firebaseApp from "../firebase.config";
import ExxonWhite from "../../assets/Images/exxon-white.png";

const db = getFirestore(firebaseApp);

function ChatBot() {
  const [step, setStep] = useState(1);
  const [userInput, setUserInput] = useState("");
  const [userEmail, setUserEmail] = useState("");
  const [messages, setMessages] = useState([]);
  const [loading, setLoading] = useState(false);
  const [showChatbox, setShowChatbox] = useState(false);
  const [isOffcanvasOpen, setIsOffcanvasOpen] = useState(false);
  const [emailProvided, setEmailProvided] = useState(false);

  const showChat = () => {
    setShowChatbox(true);
    setIsOffcanvasOpen(true);
  };

  useEffect(() => {
    // Initial welcome message after 2 seconds
    const welcomeMessageTimeout = setTimeout(() => {
      setMessages((prevMessages) => [
        ...prevMessages,
        {
          content: (
            <div className="logo-img d-flex">
              <img
                src={ExxonIconc}
                id="exxon-logo"
                className="img-thumbnail "
                alt="ExxonIcon"
                height="40px"
                width="38px"
              />
              <p>Welcome ! Do you need to expand and empower your IT team?</p>
            </div>
          ),
          isUserMessage: false,
        },
      ]);

      setLoading(true);

      // Simulate an API call or any asynchronous operation
      const apiCallTimeout = setTimeout(() => {
        setLoading(false);

        // Second message after 2 seconds
        setMessages((prevMessages) => [
          ...prevMessages,
          {
            content: (
              <div className="btn-low">
                <button className=" btn btn-success"> Yes, please </button>
              </div>
            ),
            isUserMessage: false,
          },
        ]);

        setLoading(true);

        // Simulate another API call or asynchronous operation
        const anotherApiCallTimeout = setTimeout(() => {
          setLoading(false);

          // Third message after 3 seconds
          setMessages((prevMessages) => [
            ...prevMessages,
            {
              content: (
                <div className="logo-img d-flex">
                  <img
                    src={ExxonIconc}
                    id="exxon-logo"
                    className="img-thumbnail "
                    alt="ExxonIcon"
                  />
                  <p>Can you give me your Email Address?</p>
                </div>
              ),
              isUserMessage: false,
            },
          ]);
        }, 4000);

        // Clear the third message timeout when the component unmounts
        return () => clearTimeout(anotherApiCallTimeout);
      }, 3000);

      // Clear all timeouts when the component unmounts
      return () => {
        clearTimeout(apiCallTimeout);
        clearTimeout(welcomeMessageTimeout);
      };
    }, 3000);

    // Clear the welcome message timeout when the component unmounts
    return () => clearTimeout(welcomeMessageTimeout);
  }, []); // Empty dependency array means this effect runs once on mount

  useEffect(() => {
    const chatbox = document.getElementById("chat");

    // Scroll to the bottom of the chatbox whenever new messages are added
    chatbox.scrollTop = chatbox.scrollHeight;

    const offcanvasElement = document.getElementById("offcanvasRight");
    offcanvasElement.addEventListener("hidden.bs.offcanvas", () => {
      setIsOffcanvasOpen(false);
    });

    return () => {
      offcanvasElement.removeEventListener("hidden.bs.offcanvas", () => {});
    };
  }, [messages]); // Add messages as a dependency so that the effect runs when messages change

  function sendMessage() {
    if (userInput.trim() === "") {
      return;
    }

    // Regular expression for validating an Email
    var emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

    if (!emailRegex.test(userInput)) {
      alert("Invalid email address. Please enter a valid email.");
      return;
    }

    setUserEmail(userInput);

    // Store the user's email in Firebase
    const emailRef = collection(db, "ExxonchatbotEmail");
    addDoc(emailRef, { userEmail: userInput });

    setMessages((prevMessages) => [
      ...prevMessages,
      { content: userInput, isUserMessage: true },
      {
        content: (
          <>
            <div className="last-msg d-flex">
              <img
                src={ExxonIconc}
                id="exxon-logo"
                className="img-thumbnail"
                alt="ExxonIcon"
              />
              <p>
                Thank you for providing your information! You'll receive an
                email notification shortly.
              </p>
            </div>
          </>
        ),
        isUserMessage: false,
      },
    ]);

    setStep(step + 1);
    setUserInput("");
    setEmailProvided(true);

    if (step === 1) {
      showChat();
    }
  }

  return (
    <>
      <div className="button">
        <button
          id="chatbot-toggle-button"
          className="btn button rounded-circle-button"
          type="button"
          data-bs-toggle="offcanvas"
          data-bs-target="#offcanvasRight"
          aria-controls="offcanvasRight"
        >
          <span>
            <i
              className="fa-regular fa-message fa-xl"
              style={{ color: "#fff" }}
            ></i>
          </span>
        </button>
      </div>

      <div
        className="offcanvas offcanvas-end"
        tabIndex="-1"
        id="offcanvasRight"
        aria-labelledby="offcanvasRightLabel"
      >
        <div className="offcanvas-header Echatbot">
          <img
            src={ExxonWhite}
            className="img-thumbnail align-middle"
            alt="Logo"
            style={{ width: "38%", height: "auto" }}
          />
          <button
            type="button"
            className="btn-close bg-white"
            data-bs-dismiss="offcanvas"
            aria-label="Close"
          ></button>
        </div>
        <div className="offcanvas-body">
          {step === 1 && !showChatbox ? (
            <div id="welcome-box mt-2 ">
              <h4 className="welcome-box  ">
                Welcome ! Do you need to expand and empower your IT team?
              </h4>
              <p className="Exxonsubtext">
                Get to know our expert inhouse developers, who power Exxon Info
                IT Staff Augmentation services, their skills, and what makes
                them valuable members of our team.
              </p>
              <div className="chat-btn mx-5 mt-4">
                <button className="btn" onClick={showChat}>
                  Yes, please
                </button>{" "}
                <br />
                <button
                  className="btn mt-3"
                  data-bs-toggle="offcanvas"
                  data-bs-target="#offcanvasRight"
                  aria-controls="offcanvasRight"
                >
                  No, thanks
                </button>
                <div className="text mt-4 mx-3">
                  <p>
                    By chatting with our bot, you agree to the Terms of Use and
                    Global Privacy Policy.
                  </p>
                </div>
              </div>
            </div>
          ) : null}

          <div id="chatbox" style={{ display: showChatbox ? "block" : "none" }}>
            <div id="chat">
              {messages.map((message, index) => (
                <div
                  key={index}
                  className={`message-container ${
                    message.isUserMessage ? "user-message" : "chatbot-response"
                  }`}
                  style={{
                    backgroundColor: message.isUserMessage
                      ? "#01634F"
                      : "#F1F4F7",
                    color: message.isUserMessage ? "white" : "black",
                  }}
                >
                  {message.content}
                </div>
              ))}
              {loading && (
                <div
                  className="message-container chatbot-response"
                  style={{ backgroundColor: "#F1F4F7", color: "black" }}
                >
                  Typing...
                </div>
              )}
            </div>
            {emailProvided ? null : (
              <div id="input-box">
                <input
                  type="text"
                  id="user-input"
                  placeholder="Your Business email..."
                  value={userInput}
                  onChange={(e) => setUserInput(e.target.value)}
                />
                <button id="send-btn" onClick={sendMessage}>
                  Send
                </button>
              </div>
            )}
          </div>
        </div>
      </div>
    </>
  );
}

export default ChatBot;

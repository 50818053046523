import React, { useState, useEffect } from "react";
import "./Navbar.css";
import ExxonLogo from "../../assets/ImagesWebp/ExxonLogo.webp";
import { Link as ScrollLink, animateScroll as scroll } from "react-scroll";
import { IoMdClose } from "react-icons/io";
import PopUp from "../PopUp/PopUp";
import AlternativeLogo from "../../assets/ImagesWebp/exxon-white.webp";
import { Link, useLocation, useNavigate } from "react-router-dom";

const Navbar = ({ openModal, setTargetedElement }) => {
  const location = useLocation();
  const navigate = useNavigate();
  const [isSticky, setIsSticky] = useState(false);
  const [isOpen, setIsOpen] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      setIsSticky(window.scrollY >= 0);
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const handelToggle = () => {
    setIsOpen(!isOpen);
  };

  const handleClick = (e, targetPath) => {
    e.preventDefault();
    setIsOpen(false);
    const newPath = targetPath.startsWith("/")
      ? targetPath
      : `${location.pathname}${targetPath}`;
    setTargetedElement(newPath);

    navigate(newPath);
  };

  const [isPopupVisible, setPopupVisible] = useState(false);
  const togglePopup = () => {
    setPopupVisible(true);
    // navigate("/contact");
  };

  const HandleClose = () => {
    setPopupVisible(false);
  };

  const pdfUrl = process.env.PUBLIC_URL + "/ExxonInfo.pdf";

  const Brochurepdf = () => {
    window.open(pdfUrl, "_blank");
  };
  return (
    <>
      <header
        id="isearch-header"
        className={`isearch-room ${isSticky ? "is-sticky" : ""}`}
      >
        <div className="Exxon-nav">
          <div className="container">
            <nav className="navbar navbar-expand-lg navbar-light">
              <Link
                to="/"
                className="navbar-brand active router-link-active"
                aria-current="page"
              >
                <img
                  src={isSticky ? ExxonLogo : AlternativeLogo}
                  alt="logo"
                  width="200"
                />
              </Link>
              <button
                className="navbar-toggler"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#navbarSupportedContent"
                aria-controls="navbarSupportedContent"
                aria-expanded="false"
                aria-label="Toggle navigation"
                onClick={handelToggle}
                style={{
                  backgroundColor: "white",
                }}
              >
                {isOpen ? (
                  <IoMdClose />
                ) : (
                  <span className="navbar-toggler-icon"></span>
                )}
              </button>

              <div
                id="navbarSupportedContent"
                className={`collapse navbar-collapse mean-menu navbar-collapse collapse ${
                  isOpen ? "open" : ""
                }`}
                style={{
                  justifyContent: "space-around",
                }}
              >
                <ul className="navbar-nav ml-auto mt-2">
                  <li className="nav-item">
                    <ScrollLink
                      activeClass="active"
                      to="ExxonAboutSection"
                      spy={true}
                      smooth={true}
                      duration={500}
                      className="nav-link"
                      onClick={(e) => handleClick(e, "/#ExxonAboutSection")}
                    >
                      About
                    </ScrollLink>
                  </li>

                  <li className="nav-item">
                    <ScrollLink
                      activeClass="active"
                      to="ExxonWhatWeSection"
                      spy={true}
                      smooth={true}
                      duration={500}
                      className="nav-link"
                      onClick={(e) => handleClick(e, "/#ExxonWhatWeSection")}
                    >
                      What We do
                    </ScrollLink>
                  </li>
                  <li className="nav-item">
                    <ScrollLink
                      activeClass="active"
                      to="ExxonWhyUsSection"
                      spy={true}
                      smooth={true}
                      duration={500}
                      className="nav-link"
                      onClick={(e) => handleClick(e, "/#ExxonWhyUsSection")}
                    >
                      Why Choose Us
                    </ScrollLink>
                  </li>

                  <li className="nav-item">
                    <Link className="nav-link" to="/blog">
                      Blogs
                    </Link>
                  </li>

                  <li className="nav-item">
                    <ScrollLink onClick={togglePopup} className="nav-link">
                      Contact
                    </ScrollLink>
                  </li>
                </ul>

                <ul className="isearch-secondary nav ml-auto mt-2 ">
                  <li className="hare-talent">
                    <Link
                      activeClass="active"
                      to="/shcedulecall"
                      className="nav-link"
                      style={{ textDecoration: "none", color: "white" }}
                    >
                      Schedule a Call
                    </Link>
                  </li>

                  <li className="hare-talent mx-2">
                    <a className="nav-link" onClick={Brochurepdf}>
                      E-Brochure
                    </a>
                  </li>
                </ul>
              </div>
            </nav>
          </div>
        </div>
      </header>

      <PopUp isVisible={isPopupVisible} onClose={HandleClose} />
    </>
  );
};

export default Navbar;

import React from "react";
import "./Footer.css";
import { HiOutlinePhone } from "react-icons/hi2";
import StartLogo from "../../assets/ImagesWebp/Star.webp";
import Goodfrims from "../../assets/ImagesWebp/goodfirms.webp";
import appfuture from "../../assets/ImagesWebp/NewApp.webp";
import google from "../../assets/ImagesWebp/google.webp";
import clutch from "../../assets/ImagesWebp/clutch.webp";
import UKLogo from "../../assets/ImagesWebp/UK.webp";
import USlogo from "../../assets/ImagesWebp/us.webp";
import indiaLogo from "../../assets/ImagesWebp/india.webp";
import mailLogo from "../../assets/ImagesWebp/mail.webp";
import { Link } from "react-router-dom";

const Footer = () => {
  const iconStyle = {
    fontSize: "20px",
    color: "white",
  };

  return (
    <>
      <footer className="Exxon-Footer">
        <div className="container">
          <div className="row">
            <div className="col-lg-3">
              <h5 className="ExxonFooter-header">Industry We Serve</h5>
              <div className="my-4">
                <div className="my-2">
                  <span className="Exonfoter-link">Cybersecurity</span>
                </div>

                <div className="my-2">
                  <span className="Exonfoter-link">Cloud Computing</span>
                </div>

                <div className="my-2">
                  <span className="Exonfoter-link">
                    Data Science & Analytics
                  </span>
                </div>

                <div className="my-2">
                  <span className="Exonfoter-link">Project Management</span>
                </div>

                <div className="my-2">
                  <span className="Exonfoter-link">Healthcare</span>
                </div>

                <div className="my-2">
                  <span className="Exonfoter-link">ERP tools</span>
                </div>
              </div>
            </div>
            <div className="col-lg-3" style={{ width: "20%" }}>
              <h1 className="ExxonFooter-header">Technologies</h1>
              <div className="my-4">
                <div className="my-2">
                  <span className="Exonfoter-link">Front-End</span>
                </div>

                <div className="my-2">
                  <span className="Exonfoter-link">Back-End</span>
                </div>

                <div className="my-2">
                  <span className="Exonfoter-link">Mobile</span>
                </div>

                <div className="my-2">
                  <span className="Exonfoter-link">E-Commerce</span>
                </div>

                <div className="my-2">
                  <span className="Exonfoter-link">Cloud</span>
                </div>
              </div>
            </div>
            <div className="col-lg-3">
              <h1 className="ExxonFooter-header">Our Address</h1>
              <div className="my-4">
                <span>
                  Gera's Imperium Alpha, Near Eon IT Park, Kharadi, Pune -
                  411014 <br />
                  Maharashtra (India).
                </span>
              </div>
              <div className="my-4">
                <span>
                  <img src={mailLogo} alt="emailLogo" className="email-logo" />
                  <a href="mailto:mkt@exxoninfo.com" className="email-anch">
                    mkt@exxoninfo.com
                  </a>
                </span>
              </div>
            </div>
            <div className="col-lg-3">
              <iframe
                title="Location Map"
                src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3782.3824607765223!2d73.95239607494348!3d18.55678826806748!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3bc2c3c1479e6abd%3A0x3d3672047e8413fe!2sGera&#39;s%20Imperium%20Alpha!5e0!3m2!1sen!2sin!4v1702102753119!5m2!1sen!2sin"
                style={{
                  border: "0",
                  height: "260px",
                  marginBottom: "10px",
                  width: "100%",
                  borderRadius: "20px",
                }}
                loading="lazy"
                referrerPolicy="no-referrer-when-downgrade"
              ></iframe>
            </div>
          </div>
        </div>

        <hr className="footerhr" />
        <div className="container">
          <div className="row" style={{ padding: "20px 1px" }}>
            <div className="col-lg-6">
              <div className="row">
                <div className="col-lg-3 Exxonc">
                  <a
                    target="_blank"
                    href="https://www.goodfirms.co/company/exxon-info"
                    className="text-decoration-none text-white"
                  >
                    <div className="row ExxonUperpart">
                      <div className="col-lg-6 d-flex justify-content-center align-items-center">
                        <img
                          src={StartLogo}
                          className="start-exon"
                          alt="StartLogo"
                        />
                      </div>
                      <div className="col-lg-6">
                        <span>4.7</span>
                      </div>
                    </div>

                    <img
                      src={Goodfrims}
                      alt="Goodfrims"
                      className="ExxonUnderImg"
                    />
                  </a>
                </div>

                <div className="col-lg-3 Exxonc">
                  <a
                    href="https://www.appfutura.com/companies/exxon-info"
                    target="_blank"
                    className="text-decoration-none text-white"
                  >
                    <div className="row ExxonUperpart">
                      <div className="col-lg-6 d-flex justify-content-center align-items-center">
                        <img
                          src={StartLogo}
                          className="start-exon"
                          alt="startlogo"
                        />
                      </div>
                      <div className="col-lg-6">
                        <span>4.8</span>
                      </div>
                    </div>
                    <img
                      src={appfuture}
                      alt="appfuture"
                      style={{ width: "90%", height: "auto" }}
                    />
                  </a>
                </div>

                <div className="col-lg-3 Exxonc">
                  <div className="row ExxonUperpart">
                    <div className="col-lg-6 d-flex justify-content-center align-items-center">
                      <img
                        src={StartLogo}
                        className="start-exon"
                        alt="startLogo"
                      />
                    </div>
                    <div className="col-lg-6">
                      <span>4.5</span>
                    </div>
                  </div>
                  <img src={google} alt="google" className="ExxonUnderImg" />
                </div>

                <div className="col-lg-3 Exxonc">
                  <a
                    target="_blank"
                    href="https://clutch.co/profile/exxon-info?_gl=1*16azeuf*_ga*NzA2MDM0MDcuMTcwMjk4NTA5MA..*_ga_D0WFGX8X3V*MTcwNDE5OTAzNS43LjEuMTcwNDE5OTA1Mi40My4wLjA.*_fplc*SG45N0hudSUyQkxHQlVVVmklMkZwNXdJcUhCdEhGJTJGZjNFVnJzRklBU0Z5SXhaUUt6Z2Roc1NSZWpYd1UlMkZRYzdCb2lnaFJFMm1ncnNlWjQ5aCUyRiUyRjRWSHlLemVkcjVOc0VhZXoxVm5DUU1pZDJ0UjlxYlBXc0RmVVVBamhRZE4wJTJGQ2clM0QlM0Q.#highlights"
                    className="text-decoration-none text-white"
                  >
                    <div className="row ExxonUperpart">
                      <div className="col-lg-6 d-flex justify-content-center align-items-center">
                        <img
                          src={StartLogo}
                          className="start-exon"
                          alt="startLogo"
                        />
                      </div>
                      <div className="col-lg-6">
                        <span>4.6</span>
                      </div>
                    </div>
                    <img src={clutch} alt="clutch" className="ExxonUnderImg" />
                  </a>
                </div>
              </div>
            </div>

            <div className="col-lg-6">
              <div className="row">
                <div className="col-lg-4">
                  <div className="row Exxon-Numberrow">
                    <div
                      className="col-lg-6"
                      style={{
                        marginRight: "-22px",
                        marginTop: "-9px",
                        marginLeft: "17px",
                      }}
                    >
                      <img
                        src={UKLogo}
                        alt="United Kingdom"
                        className="country-logo"
                      />
                    </div>
                    <div className="col-lg-6">
                      <p>UK</p>
                    </div>
                  </div>
                  <p className="Exxon-Call" style={{ marginLeft: "17px" }}>
                    <HiOutlinePhone style={iconStyle} />
                    <a href="tel:+44 7818 818981">+44 7818 818981</a>
                  </p>
                </div>

                <div className="col-lg-4">
                  <div className="row Exxon-Numberrow">
                    <div className="col-lg-6" style={{ marginRight: "-22px" }}>
                      <img src={USlogo} alt="usa" className="country-logo" />
                    </div>
                    <div className="col-lg-6">
                      <p>USA</p>
                    </div>
                  </div>
                  <p className="Exxon-Call">
                    <HiOutlinePhone style={iconStyle} />
                    <a href="tel:+1 618 423 7605">+1 618 423 7605</a>
                  </p>
                </div>
                <div className="col-lg-4">
                  <div className="row Exxon-Numberrow">
                    <div className="col-lg-6" style={{ marginRight: "-22px" }}>
                      <img
                        src={indiaLogo}
                        alt="india"
                        className="country-logo"
                      />
                    </div>
                    <div className="col-lg-6">
                      <p>India</p>
                    </div>
                  </div>
                  <p className="Exxon-Call">
                    <HiOutlinePhone style={iconStyle} />
                    <a href="tel:+91 9175277758">+91 9175277758</a>
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <hr className="footerhr" />

        <div className="ExxonFooter-Copyrght">
          Copyright&nbsp;&copy;&nbsp;2017-2024 |&nbsp;Exxon Info&nbsp;|&nbsp;
          <Link
            to="/privacypolicy"
            style={{ textDecoration: "none", color: "white" }}
          >
            Privacy Policy{" "}
          </Link>
          &nbsp;|&nbsp; All Rights Reserved
        </div>
        <div></div>
      </footer>
    </>
  );
};

export default Footer;

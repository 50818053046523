import React from "react";
import "./Blogs.css";
import { Link } from "react-router-dom";
import adv from "../../assets/ImagesWebp/Advantages.webp";
import staff from "../../assets/ImagesWebp/StaffConsulting.webp";
import Revo from "../../assets/ImagesWebp/Revo.webp";
import Top7 from "../../assets/ImagesWebp/Top7.webp";
import Top10 from "../../assets/ImagesWebp/Top10.webp";
import HiringITTeam from "../../assets/ImagesWebp/HiringITTeamblog6.webp";
import Empowering from "../../assets/Images/empoeringGlobalBanner.png";
import Accelerarting from "../../assets/Images/Acceleratingglobalbusiness.png";
import offshore from "../../assets/Images/offshoreouter.png";
import { Helmet } from "react-helmet";
import { off } from "firebase/database";

const Blogs = () => {
  return (
    <>
      <Helmet>
        <title>Exxon Info || Blogs</title>
        <link rel="canonical" href="https://exxoninfo.com/blog" />
      </Helmet>
      <div>
        <section className="blogs-section-one">
          <div className="container">
            <div className="row">
              <div className="col-lg-12 col-md-12">
                <h1 className="ExxonBlogTitle">Blogs</h1>
                <div className="ExxonBlogPara">
                  Our viewpoint on the problems influencing companies around the
                  world.
                </div>
              </div>
            </div>
          </div>
        </section>

        <section className="blog-sectin-two">
          <div className="container">
            <div className="row" id="1">
              <div className="col-md-4 my-3">
                <Link
                  to="/blog/Offshore-Staff-Augmentation"
                  className="col-md-4 my-3 text-decoration-none"
                >
                  <div className="card Exxonbox">
                    <div className="px-4 pt-4">
                      <img
                        className="Exxonblog-img"
                        src={offshore}
                        alt="Exxonblog-img"
                      />
                    </div>
                    <div className="Exxon-blogbody px-4">
                      <p className="Exxoncard-date">10 May, 2024</p>
                      <h4 className="ExxonCardinner">
                        Offshore Staff Augmentation: A Key Strategy for Business
                        Growth
                      </h4>
                    </div>
                  </div>
                </Link>
              </div>
              <div className="col-md-4 my-3">
                <Link
                  to="/blog/accelerating-global-business"
                  className="col-md-4 my-3 text-decoration-none"
                >
                  <div className="card Exxonbox">
                    <div className="px-4 pt-4">
                      <img
                        className="Exxonblog-img"
                        src={Accelerarting}
                        alt="Exxonblog-img"
                      />
                    </div>
                    <div className="Exxon-blogbody px-4">
                      <p className="Exxoncard-date">18 April, 2024</p>
                      <h4 className="ExxonCardinner">
                        Accelerating Global Business Agility: Exxon Info's
                        Premier IT Staff Augmentation Services from India
                      </h4>
                    </div>
                  </div>
                </Link>
              </div>

              <div className="col-md-4 my-3">
                <Link
                  to="/blog/empowering-global"
                  className="col-md-4 my-3 text-decoration-none"
                >
                  <div className="card Exxonbox">
                    <div className="px-4 pt-4">
                      <img
                        className="Exxonblog-img"
                        src={Empowering}
                        alt="Exxonblog-img"
                      />
                    </div>
                    <div className="Exxon-blogbody px-4">
                      <p className="Exxoncard-date">30 March, 2024</p>
                      <h4 className="ExxonCardinner">
                        Empowering Global Enterprises: Exxon Info's Trailblazing
                        IT Staffing Solutions from India
                      </h4>
                    </div>
                  </div>
                </Link>
              </div>

              <div className="col-md-4 my-3">
                <Link
                  to="/blog/hiring-IT-Team"
                  className="col-md-4 my-3 text-decoration-none"
                >
                  <div className="card Exxonbox">
                    <div className="px-4 pt-4">
                      <img
                        className="Exxonblog-img"
                        src={HiringITTeam}
                        alt="Exxonblog-img"
                      />
                    </div>
                    <div className="Exxon-blogbody px-4">
                      <p className="Exxoncard-date">20 Feb, 2024</p>
                      <h4 className="ExxonCardinner">
                        Hiring Your IT Dream Team: Perfect Team for Your Project
                      </h4>
                    </div>
                  </div>
                </Link>
              </div>
              <div className="col-md-4 my-3">
                <Link
                  to="/blog/top-ten-programming-language"
                  className="col-md-4 my-3 text-decoration-none"
                >
                  <div className="card Exxonbox">
                    <div className="px-4 pt-4">
                      <img
                        className="Exxonblog-img"
                        src={Top10}
                        alt="Exxonblog-img"
                      />
                    </div>
                    <div className="Exxon-blogbody px-4">
                      <p className="Exxoncard-date">31 Jan, 2024</p>
                      <h4 className="ExxonCardinner">
                        10 programming languages you must know in 2024
                      </h4>
                    </div>
                  </div>
                </Link>
              </div>

              <div className="col-md-4 my-3">
                <Link
                  to="/blog/top-seven-it-staffing"
                  className="col-md-4 my-3 text-decoration-none"
                >
                  <div className="card Exxonbox">
                    <div className="px-4 pt-4">
                      <img
                        className="Exxonblog-img"
                        src={Top7}
                        alt="Exxonblog-img"
                      />
                    </div>
                    <div className="Exxon-blogbody px-4">
                      <p className="Exxoncard-date">19 Jan, 2024</p>
                      <h4 className="ExxonCardinner">
                        Top 7 IT Staffing Companies in India(2024)
                      </h4>
                    </div>
                  </div>
                </Link>
              </div>

              <div className="col-md-4 my-3">
                <Link
                  to="/blog/revolutionizing-contractual-hiring"
                  className="col-md-4 my-3 text-decoration-none"
                >
                  <div className="card Exxonbox">
                    <div className="px-4 pt-4">
                      <img
                        className="Exxonblog-img"
                        src={Revo}
                        alt="Exxonblog-img"
                      />
                    </div>
                    <div className="Exxon-blogbody px-4">
                      <p className="Exxoncard-date">9 Jan, 2024</p>
                      <h4 className="ExxonCardinner">
                        Revolutionizing Contractual Hiring
                      </h4>
                    </div>
                  </div>
                </Link>
              </div>

              <div className="col-md-4 my-3">
                <Link
                  to="/blog/exxon-info's-guide"
                  className="col-md-4 my-3 text-decoration-none"
                >
                  <div className="card Exxonbox">
                    <div className="px-4 pt-4">
                      <img className="Exxonblog-img" src={staff} alt="staff" />
                    </div>
                    <div className="Exxon-blogbody px-4">
                      <p className="Exxoncard-date">5 Jan, 2024</p>
                      <h4 className="ExxonCardinner">
                        Exxon info's Guide to Navigating Workforce Solutions
                      </h4>
                    </div>
                  </div>
                </Link>
              </div>

              <Link
                to="/blog/utilizing-Contract-workers"
                className="col-md-4 my-3 text-decoration-none"
              >
                <div className="card Exxonbox">
                  <div className="px-4 pt-4">
                    <img className="Exxonblog-img" src={adv} alt="blogimg1" />
                  </div>
                  <div className="Exxon-blogbody px-4">
                    <p className="Exxoncard-date">2 Jan, 2024</p>
                    <h4 className="ExxonCardinner">
                      Advantages of Utilizing Contract Workers in the IT Sector
                      .
                    </h4>
                  </div>
                </div>
              </Link>
            </div>
          </div>
        </section>
      </div>
    </>
  );
};

export default Blogs;

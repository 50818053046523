import React, { useState, useEffect } from "react";
import "./Home.css";
import WhyUs from "../Components/WhyUs/WhyUs";
import PopUp from "../Components/PopUp/PopUp";
import GlobalClient from "../Components/GlobalClient/GlobalClient";
import ExxonHiring from "../assets/ImagesWebp/Exxon-Hirring.webp";
import PeopleFirst from "../assets/ImagesWebp/PeopleFirst.webp";
import Technology from "../Components/Technology/Technology";
import itConsultingLogo from "../assets/ImagesWebp/Itconsulting.webp";
import LongTermLogo from "../assets/ImagesWebp/Long term.webp";
import ShortTermLogo from "../assets/ImagesWebp/Short term.webp";
import StafAguLogo from "../assets/ImagesWebp/Staff augmentation.webp";
import WhyExxon from "../Components/WhyExxon/WhyExxon";
import Modal from "../Components/Navbar/Modal";

import { Link as ScrollLink, animateScroll as scroll } from "react-scroll";
import { Helmet } from "react-helmet";

const Home = ({ isModalOpen, setIsModalOpen, targetedElement }) => {
  const [isPopupVisible, setPopupVisible] = useState(false);
  // console.log("targetedElement : ", targetedElement);
  const togglePopup = () => {
    setPopupVisible(true);
  };

  const HandleClose = () => {
    setPopupVisible(false);
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };

  useEffect(() => {
    // console.log("targetElements-task", targetedElement.split("#")[1]);
    const targetElements = document.getElementById(
      targetedElement.split("#")[1]
    );
    // Scroll to the target element when the component is mounted
    scroll.scrollTo(targetElements?.offsetTop, {
      duration: 800,
      delay: 0,
      smooth: "easeInOutQuart",
    });
  }, [targetedElement]);

  return (
    <>
      <Helmet>
        <title>Exxon Info || Home</title>
        <link rel="canonical" href="https://exxoninfo.com/" />
      </Helmet>
      <div style={{ overflowX: "hidden" }}>
        {isModalOpen && <Modal closeModal={closeModal} />}

        <section className="iserach-section-one">
          <div className="container">
            <div className="row">
              <div className="col-12 col-lg-10">
                <div className="row">
                  <div className="col-12 col-xl-7">
                    <header className="home-hero--header">
                      <h1>
                        Recruit the Finest
                        <br />
                        <span>Remote Professionals</span>
                      </h1>
                    </header>
                    <h2 className="customized">
                      Customized to meet your needs.
                    </h2>{" "}
                    <br />
                    <p class="home-hero--desc">
                      With the help of the best talent scouts in the business,
                      you can utilize the potential of the most skilled IT/Tech
                      professionals on the planet and quickly grow your team in
                      a matter of days. <br />
                    </p>
                  </div>
                  <div className="col-12 col-xl-3 off-set-xl-2"></div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className="banner-section" id="hireTalentSection">
          <div className="container Exxon-banner-container">
            <div className="row justify-content-center">
              <h1 className="Exxon-banner-head">
                Hire <span>Top Software Developers on Contract!</span>
              </h1>

              <p className="Exxon-banner-para">
                We focus in following technologies:-
              </p>

              <div className="Exxon-banner-table">
                <div className="Exxon-banner-col" onClick={togglePopup}>
                  <span>React JS Developer</span>
                </div>
                <div className="Exxon-banner-col" onClick={togglePopup}>
                  <span>Node JS Developer</span>
                </div>
                <div className="Exxon-banner-col" onClick={togglePopup}>
                  <span>Angular JS Developer</span>
                </div>
                <div className="Exxon-banner-col" onClick={togglePopup}>
                  <span>Dot Net Developer</span>
                </div>
                <div className="Exxon-banner-col" onClick={togglePopup}>
                  <span>Java Developer</span>
                </div>
                <div className="Exxon-banner-col" onClick={togglePopup}>
                  <span>UI/UX Experts</span>
                </div>
                <div className="Exxon-banner-col" onClick={togglePopup}>
                  <span>MERN Developer</span>
                </div>
                <div className="Exxon-banner-col" onClick={togglePopup}>
                  <span>MEAN Developer</span>
                </div>
                <div className="Exxon-banner-col" onClick={togglePopup}>
                  <span>ioS Developer</span>
                </div>
                <div className="Exxon-banner-col" onClick={togglePopup}>
                  <span>React Native Developer</span>
                </div>
                <div className="Exxon-banner-col" onClick={togglePopup}>
                  <span>Flutter Developer</span>
                </div>
                <div className="Exxon-banner-col" onClick={togglePopup}>
                  <span>Android Developer</span>
                </div>
                <div className="Exxon-banner-col" onClick={togglePopup}>
                  <span>FullStack Developer</span>
                </div>
                <div className="Exxon-banner-col" onClick={togglePopup}>
                  <span>QA Experts</span>
                </div>
                <div className="Exxon-banner-col" onClick={togglePopup}>
                  <span>Next js Developer</span>
                </div>
                <div className="Exxon-banner-col" onClick={togglePopup}>
                  <span>Nest js Developer</span>
                </div>

                <ul className="Exxon-banner-list">
                  <li>
                    We work with clients both locally and internationally,
                    drawing more than 1500+ Clients.
                  </li>
                  <li>
                    We have hired over 500+ knowledgeable and experienced
                    software development specialists since our founding in 2020.
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </section>

        <PopUp isVisible={isPopupVisible} onClose={HandleClose} />

        <Technology />

        <WhyExxon />

        <section className="Exxon-About" id="ExxonAboutSection">
          <div className="container">
            <div className="row justify-content-center">
              <div className="col-12 col-lg-10">
                <div className="row Exxon-Aboutrow">
                  <div className="col-12 col-lg-5 col-md-6">
                    <h2>We are Capturing the Hiring Industry.</h2>

                    <p>
                      Where there is need, there is Exxon.
                      <br />
                      <br />
                      The need for IT and tech talent has grown significantly
                      over the last several years.
                      <br /> <br />
                      Our excellent outsourcing services has extensive database
                      consisting of candidates fluent and efficient in various
                      programming languages.
                      <br /> <br />
                      We have a presence in the UK, USA & Indian markets,
                      strategically expanding our reach across global
                      landscapes.
                    </p>

                    <p>
                      Our candidates possess a highly sought-after combination
                      of competencies that are highly valued in the dynamic IT
                      market. They seamlessly integrate advanced technical
                      expertise with refined communication and language skills.
                    </p>
                  </div>

                  <div className="col-12 col-lg-5 col-md-6 offset-lg-2  Exxon-hiring-indurstry">
                    <img
                      src={ExxonHiring}
                      alt="Right-img"
                      style={{ borderRadius: "20px" }}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        <GlobalClient />

        <section className="Exxon-wedo" id="ExxonWhatWeSection">
          <div className="container">
            <div className="row Exxon-Wedorow justify-content-center">
              <div className="col-12 col-lg-5 ExxonPeople">
                <img src={PeopleFirst} alt="left-img" className="Wedo-img" />
              </div>

              <div className="col-12 col-lg-5 offset-lg-2">
                <div className="Whatwedo-hiring">
                  <h3>People-First Hiring</h3>
                  <p>
                    Expanding your workforce is easier when you work with a
                    partner who understands the IT talent market and can make
                    connections with Experts who are actively looking for new
                    projects, on a local as well as worldwide scale.
                  </p>
                  <br />
                  <p>
                    Several multinational and local businesses have benefited
                    greatly from our well-established hiring procedure, which
                    has helped them grow their IT teams and hire outstanding
                    candidates who fit right in with their corporate culture.
                  </p>

                  <p>
                    We value a comprehensive assessment that considers
                    candidates' culturally fit with the company in addition to
                    their technical abilities as part of our recruitment
                    process, which goes beyond technical proficiency. We think
                    that the key to creating a productive and happy work
                    environment is a harmonious alignment with an organization's
                    values and objectives.
                  </p>
                  <p>
                    Our core focus is personalized recruitment, tailoring
                    strategies to meet the unique needs of clients and
                    candidates. We emphasize customization to ensure connections
                    are based on shared values and skill sets, fostering
                    enduring partnerships for long-term success.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section className="Exxon-Technical">
          <div className="container">
            <div className="row">
              <h1 className="Exxon-Provide-header">What we provide</h1>
            </div>

            <div className="row">
              <div className="col-lg-3">
                <div className="WeProvide-Exxon">
                  <img src={itConsultingLogo} alt="itConsultingLogo" />

                  <h1 className="ex-head">IT Consulting</h1>
                  <p>
                    The cognitive skill of coding IT consultants simplifies
                    complexities and makes it easier to achieve digital success
                    while maximizing cost savings by 80–85%.
                  </p>
                </div>
              </div>
              <div className="col-lg-3">
                <div className="WeProvide-Exxon">
                  <img src={LongTermLogo} alt="LongTermLogo" />

                  <h1 className="ex-head">Long Term Contract</h1>
                  <p>
                    Use our comprehensive IT staffing options to succeed over
                    the long haul. Invest in a workforce that is more tech-savvy
                    and dedicated to meeting your needs.
                  </p>
                </div>
              </div>
              <div className="col-lg-3">
                <div className="WeProvide-Exxon">
                  <img src={ShortTermLogo} alt="ShortTermLogo" />

                  <h1 className="ex-head">Short Term Contract</h1>
                  <p>
                    You can effectively fill in skill gaps, fulfill project
                    demands, and foster success with our qualified professionals
                    on temporary contracts.
                  </p>
                </div>
              </div>

              <div className="col-lg-3">
                <div className="WeProvide-Exxon">
                  <img src={StafAguLogo} alt="StafAguLogo" />

                  <h1 className="ex-head">Staff Augmentation</h1>
                  <p>
                    Our staff augmentation enhances your team's performance. We
                    adapt to your needs, providing skilled IT professionals
                    seamlessly integrating into projects.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </section>
        <WhyUs />
      </div>
    </>
  );
};

export default Home;

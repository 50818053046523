import React, { useState } from "react";
import "./BlogFour.css";
import { CiCalendar } from "react-icons/ci";
import TopInbet from "../../../assets/ImagesWebp/exxon1.webp";
import HireD from "../../../assets/ImagesWebp/Hire1.webp";
import PopUp from "../../PopUp/PopUp";

const BlogFour = () => {
  const [isPopupVisible, setPopupVisible] = useState(false);
  const togglePopup = () => {
    setPopupVisible(true);
  };
  const HandleClose = () => {
    setPopupVisible(false);
  };
  return (
    <>
      <div className="blog-fourSection">
        <div className="container">
          <div className="row align-items-center position-relative">
            <div className="col-12 col-md-12 col-lg-12">
              <div className="exxonblog-content">
                <h1>Top 7 IT Staffing Companies in India(2024)</h1>
              </div>
            </div>
          </div>

          <div className="row">
            <div className="ExxonBlogdate">
              <CiCalendar style={{ color: "#fff" }} />
              <span>January 19, 2024</span>
            </div>
          </div>
        </div>
      </div>

      <div className="ExxonoverviewSection">
        <div className="container">
          <div className="row">
            <div className="col-md-12 col-lg-12 mx-auto">
              <div className="blog-content-wrapper">
                <p>
                  Pune, formerly known as Poona, is one of the three techno
                  cities that forms the “Silicone Triangle” of India. It is rich
                  in culture and diversity, making it one of the best cities to
                  live in. Today, most of the technologically advanced and
                  economically successfully companies are located in Pune,
                  making it one of the best cities to work in 2024. As we look
                  ahead, the collaboration between businesses and these staffing
                  giants promises to be transformative for Pune's IT sector. The
                  synergy between the two fosters a dynamic environment
                  characterized by unprecedented growth, efficiency, and
                  expertise.
                </p>

                <p>
                  Being a tier-1 city, Pune is rich is its ever-growing market
                  for opportunities in different industries. As a result, many
                  companies have started their own staffing companies. Here is
                  the list of the &nbsp;
                  <strong>Top 7 IT Staffing Companies in Pune(2024).</strong>
                </p>
                <img
                  src={TopInbet}
                  alt="inbet"
                  style={{ height: "100%", width: "100%", marginTop: "20px" }}
                />

                <h2>1.Exxon Info</h2>
                <p>
                  Establishment: 2020 <br />
                  Website:{" "}
                  <a href="https://exxoninfo.com/" target="_blank">
                    www.exxoninfo.com
                  </a>
                  <br />
                  Address: 709, Gera’s Imperium Alpha, Near EON IT Park,
                  Kharadi, Pune, Maharashtra- 411014. Headquartered in the IT
                  hub of Pune, Exxon Info has the best staffing service known in
                  the industry. <br />
                  The company has more than 250+ clients globally with 90%
                  retention rate, making it one of the fastest growing IT
                  staffing companies in Pune. <br />
                  Exxon Info provides services like IT consulting, Short Term
                  Contracts, Long Term Contracts, and Staff Augmentation all
                  over the world. <br />
                  Exxon Info has an office in Pune, India and overseas offices
                  at United States and United Kingdom. <br />
                  In its 4 years of existence, the team is filled with skilled
                  individuals having wealth of knowledge and expertise, with
                  experienced software developers to ensure your needs totally
                  fulfilled and final product is of the highest quality. <br />
                  Headquartered in Bangalore, company has been providing one of
                  the best services in IT Staffing recruitment. <br />
                  They also have branch offices across different cities of India
                  and around the globe. <br />
                  PITCS provides full range of Staffing, recruitment & Payroll
                  solutions with a passion of combining organizations with smart
                  investments in intelligent technology and talented people with
                  their true potential
                </p>

                <h2>2. Alp Consulting</h2>
                <p>
                  Establishment: 1996 <br />
                  Website:{" "}
                  <a href="https://alp.consulting/" target="_blank">
                    www.alp.consulting
                  </a>
                  <br />
                  Address: 203, 204, 2nd Floor, Eden Hall, opposite OM
                  Supermarket, Model Colony, Shivajinagar, Pune, Maharashtra
                  411016. <br />
                  Alp is a subsidiary of Outsourcing Inc. Japan, a 5
                  billion-dollar organisation with presence in 17 countries.
                  <br />
                  Since its establishment in 1996, Alp Consulting has been one
                  of the leading operational transformation in the recruitment
                  and staffing industry in India. <br />
                  They have a corporate office in Bangalore, and branch offices
                  in Hyderabad, Chennai, Pune, Mumbai, Delhi, and Noida. They
                  also have an international office in the United States.
                </p>

                <h2>3. The NineHertz</h2>
                <p>
                  Establishment: 2008 <br />
                  Website: &nbsp;
                  <a
                    href="https://theninehertz.com/services/us-it-staffing-services"
                    target="_blank"
                  >
                    www.theninehertz.com/services/us-it-staffing-services
                  </a>{" "}
                  <br />
                  Address: - 3/1, Chitrakoot Scheme, Sector -3, Vaishali Nagar,
                  Jaipur, Rajasthan 302021 <br />
                  The NineHertz stands as a transformative IT staff augmentation
                  agency, designed to address the talent gap prevalent in IT
                  companies throughout the United States. <br />
                  Their streamlined screening process meticulously sifts through
                  top technology talents, enabling them to elevate their
                  clients' performance metrics significantly.
                </p>

                <h2>4. V3 Staffing- IT Recruitment Company</h2>
                <p>
                  Establishment: 2010 <br />
                  Website: &nbsp;
                  <a href="https://v3staffing.in/" target="_blank">
                    www.v3staffing.in
                  </a>
                  <br />
                  Address: HD 043, SY:11 WeWork Krishe Emerald, Kondapur
                  MainRoad, Laxmi Cyber City, Whitefields, Hyderabad. <br />
                  Based in Hyderabad, India, V3 Staffing is a recruiting and
                  staffing company aiming to revolutionize the dynamic human
                  resources sector in the country. <br />
                  Their team of skilled and experienced professionals boasts a
                  proven track record of success in connecting companies with
                  top-quality candidates for Permanent Recruitment, Contract
                  Staffing, and RPO projects. <br />
                </p>

                <h2>5. Gracira Technologies</h2>
                <p>
                  Establishment: 2017 <br />
                  Website: &nbsp;
                  <a href="http://www.gracira.com/" target="_blank">
                    www.gracira.com
                  </a>{" "}
                  <br />
                  Address: #186,4th cross, Niranthara Layout, Baglur Main Road,
                  Bangalore – 560063, Karnataka. <br />
                  Their skilled technical recruiters engage in proactive
                  nationwide recruitment of technical professionals. Employing
                  the Recruiting Methodology, they meticulously prescreen
                  candidates before incorporating them into their dynamic
                  database.
                  <br />
                  Furthermore, they are dedicated to ensure that the proficient
                  professionals they introduce to their organization not only
                  possess the requisite skills but also seamlessly integrate
                  into their team and align with their organizational culture.{" "}
                  <br />
                </p>

                <h2>6. PITCS (Poonam IT Consulting Services)</h2>
                <p>
                  Establishment: 2006 <br />
                  Website:{" "}
                  <a href="https://pitcs.in/" target="_blank">
                    www.pitcs.in
                  </a>{" "}
                  <br />
                  Address: G232,Akhsay Complex 187, Balkrishna Sakharam Dhole
                  Patil Rd, Sangamvadi, Pune, Maharashtra 411001 <br />
                  Poonam IT Consulting Services, as an ISO 27001:2013 certified
                  global workforce solutions provider with more than two decades
                  of industry expertise, stands poised as a reliable and
                  forward-thinking partner for organizations seeking excellence
                  in IT staffing services.
                </p>
                <h2>7. Acreaty</h2>
                <p>
                  Establishment- 2001 <br />
                  Website- &nbsp;
                  <a href="https://www.acreaty.com/" target="_blank">
                    www.acreaty.com
                  </a>
                  <br />
                  Address: C 22/9, Block C, Sector 57, Noida, Uttar Pradesh-
                  201301 <br />
                  Acreaty Management Consultant Private Limited is an
                  organization that provides a comprehensive range of service
                  solutions for the fundamental foundation of any organization -
                  Human Resource. <br />
                  Their core set of offerings includes Business Strategies,
                  Process and People Outsourcing, Recruitments, and Training.
                  The company traces its origins back to 2001 when it commenced
                  operations with a modest office in Delhi, eventually expanding
                  its presence across India. <br />
                  Over 15 years, Acreaty has established a firm and stable
                  foothold in the market, setting benchmarks for its services at
                  all levels.
                </p>
                <p>
                  In conclusion, as we navigate the ever-evolving landscape of
                  the IT industry in Pune, the top 7 IT staffing companies of
                  2024 stand out as pillars of excellence and innovation. These
                  organizations have demonstrated a commitment to providing
                  cutting-edge solutions and connecting businesses with the
                  brightest talent in the field. In a city that thrives on
                  technological advancements, these staffing companies play a
                  pivotal role in shaping the future of IT by facilitating the
                  seamless integration of skilled professionals into the
                  workforce. As we look ahead, the collaboration between
                  businesses and these staffing giants promises to propel Pune's
                  IT sector to new heights, fostering a dynamic environment of
                  growth, efficiency, and expertise and{" "}
                  <a href="https://exxoninfo.com/" target="_blank">
                    Exxon Info
                  </a>{" "}
                  &nbsp; is people’s trusted choice.
                </p>
                <img
                  src={HireD}
                  alt="HireDeveloper"
                  className="HireDev"
                  onClick={togglePopup}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      <PopUp isVisible={isPopupVisible} onClose={HandleClose} />
    </>
  );
};

export default BlogFour;

import React, { useState } from "react";
import { CiCalendar } from "react-icons/ci";
import PerfectTeam from "../../../assets/ImagesWebp/PerfectTeam.webp";
import PopUp from "../../PopUp/PopUp";
import "../BlogSix/BlogSix.css";
import HireD from "../../../assets/ImagesWebp/Hire1.webp";
import CollabrateWithus from "../../../assets/ImagesWebp/Collabratewithus.webp";

const BlogSix = () => {
  const [isPopupVisible, setPopupVisible] = useState(false);
  const togglePopup = () => {
    setPopupVisible(true);
  };
  const HandleClose = () => {
    setPopupVisible(false);
  };
  return (
    <>
      <div className="blog-sixSection">
        <div className="container">
          <div className="row align-items-center position-relative">
            <div className="col-12 col-md-12 col-lg-12">
              <div className="exxonblog-content">
                <h1>
                  Hiring Your IT Dream Team: Perfect
                  <br></br>Team for Your Project
                </h1>
              </div>
            </div>
          </div>

          <div className="row">
            <div className="ExxonBlogdate">
              <CiCalendar style={{ color: "#fff" }} />
              <span>February 20, 2024</span>
            </div>
          </div>
        </div>
      </div>

      <div className="ExxonoverviewSection">
        <div className="container">
          <div className="row">
            <div className="col-md-12 col-lg-12 mx-auto">
              <div className="blog-content-wrapper">
                <p>
                  Are you looking to assemble the perfect IT team for your
                  Projects? Look no further, because at {}
                  <a href="https://exxoninfo.com/" target="_blank">
                    Exxon info
                  </a>
                  , we recognize the vital role that talent plays in driving
                  success. Whether you need Business Analysts, Project Managers,
                  full-stack wizards, frontend experts, backend developers, QA
                  specialists, web designers, UI/UX geniuses, or manual testers,
                  we've got you covered. With our expertise and dedication at
                  Exxon Info, we're here to partner with you on this journey.
                </p>
                <img
                  src={PerfectTeam}
                  alt="Perfect Team for your Project"
                  style={{
                    height: "80%",
                    width: "80%",
                    marginTop: "20px",
                    marginLeft: "6rem",
                  }}
                />
                <h2>Embark on Your IT Journey with Confidence</h2>
                <p>
                  Building the right IT team can be both exhilarating and
                  challenging. But fear not, because at Exxon Info, we're here
                  to guide you through every step. As an IT staffing company, we
                  specialize in helping businesses like yours find the perfect
                  talent to fuel their growth. From understanding your
                  requirements to delivering the right candidates, we'll work
                  closely with you to ensure a seamless process. So why wait?
                  Let's dive into building your dream team with Exxon Info
                  today!
                </p>
                <h2>Defining Your Needs Clearly</h2>
                <p>
                  The first step in building your dream IT team is to define
                  your needs clearly. Are you in need of individuals with
                  expertise in business analysis, project management, full-stack
                  development, frontend or backend development, quality
                  assurance, web design, UI/UX design, or manual testing? By
                  outlining your requirements upfront, you can streamline the
                  hiring process and ensure that you find the right fit for your
                  team.
                </p>
                <h2>Leveraging Our Specialized Recruitment Expertise</h2>
                <p>
                  Once you've identified your needs, it's time to leverage our
                  specialized recruitment expertise. At Exxon Info, we have a
                  team of experienced recruiters who specialize in IT staffing.
                  With their deep knowledge of the industry and vast network of
                  qualified candidates, they'll help you find top-tier talent
                  that may not be reachable through traditional channels.
                </p>
                <h2>Prioritizing Quality Over Quantity</h2>
                <p>
                  Quality is paramount when building your IT team, which is why
                  we prioritize it above all else. Our rigorous candidate
                  selection process ensures that every individual we recommend
                  possesses the expertise and professionalism needed to excel in
                  their role. With Exxon Info, you can rest assured that you're
                  getting the best of the best for your team.
                </p>
                <h2>Looking Beyond Technical Skills</h2>
                <p>
                  While technical proficiency is important, we understand that
                  it's not the only factor to consider when hiring IT
                  professionals. Soft skills such as communication,
                  problem-solving, and teamwork are equally important,
                  especially in dynamic project environments. That's why we take
                  a holistic approach to recruitment, considering both technical
                  expertise and interpersonal skills to ensure a well-rounded
                  team that can collaborate effectively and drive success.
                </p>
                <h2>Embracing Flexibility and Innovation</h2>
                <p>
                  In the fast-paced world of technology, adaptability and
                  innovation are key to staying ahead of the curve. That's why
                  we prioritize candidates who demonstrate a willingness to
                  learn, experiment with new technologies, and adapt to changing
                  circumstances. At Exxon Info, we seek out innovative thinkers
                  who can bring fresh perspectives and creative solutions to
                  your projects, helping you stay agile and competitive in
                  today's digital landscape.
                </p>
                <img
                  src={CollabrateWithus}
                  alt="Collabrate with us"
                  style={{
                    height: "80%",
                    width: "80%",
                    marginTop: "20px",
                    marginLeft: "6rem",
                  }}
                />
                <h2>Why Choose Exxon Info?</h2>
                <p>
                  <strong>•Tailored Solutions: </strong>
                  We provide customized solutions to match your specific {}
                  <a
                    href="https://exxoninfo.com/top-seven-it-staffing"
                    target="_blank"
                  >
                    staffing
                  </a>{" "}
                  needs.
                </p>
                <p>
                  <strong>•Extensive Talent Network: </strong>
                  With our vast network, we have access to top-tier talent
                  across various fields.
                </p>
                <p>
                  <strong>• Quality Assurance: </strong>
                  Our rigorous screening process ensures that we deliver only
                  the best candidates.
                </p>
                <p>
                  <strong>• Timely Delivery: </strong>
                  We prioritize prompt results to minimize downtime and maximize
                  productivity.
                </p>
                <p>
                  <strong>• Flexible Options: </strong>
                  Whether you need temporary staff, permanent hires, or
                  specialized professionals, we offer flexible solutions to suit
                  your requirements.
                </p>
                <h2>Our Services Include:</h2>
                <p>
                  •
                  <a
                    href="https://exxoninfo.com/exxon-info's-guide"
                    target="_blank"
                  >
                    Staff Augmentation
                  </a>
                </p>
                <p>
                  •
                  <a
                    href="https://exxoninfo.com/top-seven-it-staffing"
                    target="_blank"
                  >
                    IT Staffing
                  </a>
                </p>
                <p>
                  •{" "}
                  <a
                    href="https://exxoninfo.com/exxon-info's-guide"
                    target="_blank"
                  >
                    IT Consulting
                  </a>
                </p>
                <p>
                  •{" "}
                  <a
                    href="https://exxoninfo.com/utilizing-Contract-workers"
                    target="_blank"
                  >
                    Long Term Contract
                  </a>
                </p>
                <p>
                  •
                  <a
                    href="https://exxoninfo.com/revolutionizing-contractual-hiring"
                    target="_blank"
                  >
                    Short Term Contract
                  </a>
                </p>
                <img
                  src={HireD}
                  alt="HireDeveloper"
                  className="HireDev"
                  onClick={togglePopup}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      <PopUp isVisible={isPopupVisible} onClose={HandleClose} />
    </>
  );
};

export default BlogSix;

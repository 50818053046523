import React, { useEffect, useState } from "react";
import Home from "./Home/Home";
import ChatBot from "./Components/FixedButton/ChatBot";
import {
  BrowserRouter as Router,
  Route,
  Routes,
  useLocation,
} from "react-router-dom";
import Blogs from "./Components/Blogs/Blogs";
import Navbar from "./Components/Navbar/Navbar";
import Footer from "./Components/Footer/Footer";
import BlogOne from "./Components/Blogs/BlogOne/BlogOne";
import BlogTwo from "./Components/Blogs/BlogTwo/BlogTwo";
import BlogThree from "./Components/Blogs/BlogThree/BlogThree";
import { Helmet, HelmetProvider } from "react-helmet-async";
import ErrorPage from "./Components/ErrorPage/ErrorPage";
import Whatsapp from "./Components/FixedButton/Whatsapp";
import LinkedIn from "./Components/FixedButton/LinkedIn";
import BlogFour from "./Components/Blogs/BlogFour/BlogFour";
import BlogFive from "./Components/Blogs/BlogFive/BlogFive";
import BlogSix from "./Components/Blogs/BlogSix/BlogSix";
import BlogSeven from "./Components/Blogs/BlogSeven/BlogSeven";
import ThankYouPage from "./Components/ThankYouPage/ThankYouPage";
import BlogEight from "./Components/Blogs/BlogEight/BlogEight";
import BlogNine from "./Components/Blogs/BlogNine/BlogNine";
import PopUp from "./Components/PopUp/PopUp";
import Modal from "./Components/Navbar/Modal";
import Privacy from "./Components/Privacy/Privacy";

export function ScrollToTop() {
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);
}
const App = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [targetedElement, setTargetedElement] = useState("");
  const openModal = () => {
    setIsModalOpen(true);
  };

  useEffect(() => {
    const chatbotTimeout = setTimeout(() => {
      // Trigger the chatbot to open after 3 seconds
      document.getElementById("chatbot-toggle-button").click();
    }, 1000);

    return () => clearTimeout(chatbotTimeout);
  }, []);
  return (
    <div>
      <HelmetProvider>
        <Helmet>
          <title>ExxonInfo</title>
          <meta
            name="description"
            content="Welcome to the ExxonInfo, Where we redefine IT staffing to meet the evolving needs of your business. With a commitment to excellence and a proven track record, we stand as your reliable partner in acquiring top-tier IT talent"
          />
          <meta
            name="keywords"
            content="web application development,web designer,	
        IT company,	
        IT services,	
        IT support,"
          />
          <meta name="author" content="ExxonInfo ITstaffing" />
          <meta http-equiv="Content-Type" content="text/html; charset=utf-8" />
          <meta name="language" content="English" />
          <link rel="canonical" href="https://exxoninfo.com/" />
          <link
            rel="sitemap"
            type="application/xml"
            title="Sitemap"
            href="https://exxoninfo.com/sitemap.xml"
          />
        </Helmet>

        <Router>
          <ScrollToTop />
          <Navbar
            openModal={openModal}
            setTargetedElement={setTargetedElement}
          />
          <Routes>
            <Route
              path="/"
              element={
                <Home
                  isModalOpen={isModalOpen}
                  setIsModalOpen={setIsModalOpen}
                  targetedElement={targetedElement}
                />
              }
            />
            <Route path="/blog" element={<Blogs />} />
            <Route
              path="/blog/utilizing-Contract-workers"
              element={<BlogOne />}
            />
            <Route path="/blog/exxon-info's-guide" element={<BlogTwo />} />
            <Route
              path="/blog/revolutionizing-contractual-hiring"
              element={<BlogThree />}
            />
            <Route path="/blog/top-seven-it-staffing" element={<BlogFour />} />
            <Route
              path="/blog/top-ten-programming-language"
              element={<BlogFive />}
            />
            <Route
              path="/blog/Offshore-Staff-Augmentation"
              element={<BlogNine />}
            />
            <Route path="/blog/hiring-IT-Team" element={<BlogSix />} />
            <Route path="/blog/empowering-global" element={<BlogSeven />} />
            <Route
              path="/blog/accelerating-global-business"
              element={<BlogEight />}
            />
            <Route path="/thankyou" element={<ThankYouPage />} />
            <Route path="*" element={<ErrorPage />} />
            <Route path="/contact" element={<PopUp></PopUp>}></Route>
            <Route path="/shcedulecall" element={<Modal />} />
            <Route path="/privacypolicy" element={<Privacy />} />
          </Routes>
          <Footer />
        </Router>
        <ChatBot />
      </HelmetProvider>
      <Whatsapp />
      <LinkedIn />
    </div>
  );
};

export default App;

import React from "react";
import "./GlobalClient.css";
import CountUpWithViewport from "../GlobalClient/CountUp";
import GlobalC from "../../assets/ImagesWebp/GlobalClient.webp";

const GlobalClient = () => {
  return (
    <>
      <section className="Exxon-Client-Section">
        <div className="container">
          <div className="row">
            <div className="col-lg-4">
              <div className="Exxon-cleint">
                <img src={GlobalC} alt="GlobalC" />
                <p>
                  Best Company <br /> For IT Staffing
                </p>
              </div>
            </div>
            <div className="col-lg-4">
              <div className="Exxon-Global-Client">
                <CountUpWithViewport start={0} end={250} duration={2000} />
                <span className="sign">+</span>
                <p>Clients globally</p>
              </div>
            </div>
            <div className="col-lg-4">
              <div className="Exxon-Global-Client">
                <CountUpWithViewport start={0} end={90} duration={1500} />
                <span className="sign">%</span>
                <p>Retention Rate</p>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default GlobalClient;

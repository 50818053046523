import React from "react";
import "./WhyExxon.css";

const WhyExxon = () => {
  return (
    <div>
      <section className="Why-Exxon-Section">
        <div className="container">
          <div className="row">
            <h2 className="Exxon-hire">
              Where Excellence Converges with Inspirational Innovation
            </h2>
          </div>
          <div className="row">
            <div className="col-lg-6">
              <div className="Exxon-left-side">
                <h3>Why Exxon?</h3>
                <p>With expertise in temporary staffing</p>
                <p>Powerful Team for Requirements</p>
                <p>more than 10 years of domain expertise</p>
                <p>Instant Participation</p>
                <p>
                  Developer Pool with Background Checks (Node.js,.Net, QA
                  Automation)
                </p>
                <p>Promised Prices</p>
              </div>
            </div>
            <div className="col-lg-6">
              <div className="Exxon-right-side">
                <h3 className="Exxon-head-Process">Process</h3>
                <p className="Exxon-details">
                  Send Us Your Complete Project Specifications
                </p>
                <p className="Exxon-select">
                  Choose Potential Candidates for the Screening Procedure
                </p>
                <p className="Exxon-Interview">
                  Conduct Interview With Shortlisted Candidates
                </p>

                <p className="Exxon-assign">Onboarding and assigning tasks.</p>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default WhyExxon;

import React from "react";
import "./LinkedIn.css";
import LinkLogo from "../../assets/ImagesWebp/LinkedIn.webp";
const LinkedIn = () => {
  return (
    <>
      <div className="linkedin-button">
        <a href="https://www.linkedin.com/company/exxon-info/" target="_blank">
          <img src={LinkLogo} alt="ExxonLinked" className="linkedIn-logo" />
        </a>
      </div>
    </>
  );
};

export default LinkedIn;

import React from "react";
import "./WhyUs.css";

const WhyUs = () => {
  return (
    <>
      <div className="Exxon-WhyUs" id="ExxonWhyUsSection">
        <div className="container">
          <div className="row">
            <h2 className="text-center mt-5">Why Choose Us</h2>
            <p>See the reviews of our Partners:</p>
          </div>

          <div className="row justify-content-center Exxon-ChooseUs-row">
            <div className="col-lg-4">
              <div className="Exxon-Oppening-Item">
                <svg
                  width="40"
                  height="30"
                  viewBox="0 0 40 30"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <g opacity="0.2">
                    <path
                      d="M0 19.2589C0 7.92167 9.45455 1.69587 14.1818 0.00012207C8.36364 4.6513 8.12121 9.93233 8.72727 11.9915C14.9091 10.9013 18.9091 14.5351 18.9091 20.7124C18.9091 25.945 16 28.5451 13.0909 29.4334C8.72727 30.7658 0 30.5962 0 19.2589Z"
                      fill="#2490EF"
                    ></path>
                    <path
                      d="M21.0898 19.2589C21.0898 7.92167 30.5444 1.69587 35.2717 0.00012207C29.4535 4.6513 29.2111 9.93233 29.8171 11.9915C35.9989 10.9013 39.9989 14.5351 39.9989 20.7124C39.9989 25.945 37.0898 28.5451 34.1808 29.4334C29.8171 30.7658 21.0898 30.5962 21.0898 19.2589Z"
                      fill="#2490EF"
                    ></path>
                  </g>
                </svg>
                <p>
                  Exxon Info has been an absolute game-changer for our
                  outsourcing needs! The candidates they provided us with were
                  not just skilled professionals; they were strategic partners.
                  Their expertise and dedication have elevated our projects, and
                  working with Exxon Info feels like having an extension of our
                  own team. Kudos to them for delivering excellence in
                  outsourcing!
                </p>
              </div>
            </div>
            <div className="col-lg-4">
              <div className="Exxon-Oppening-Item">
                <svg
                  width="40"
                  height="30"
                  viewBox="0 0 40 30"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <g opacity="0.2">
                    <path
                      d="M0 19.2589C0 7.92167 9.45455 1.69587 14.1818 0.00012207C8.36364 4.6513 8.12121 9.93233 8.72727 11.9915C14.9091 10.9013 18.9091 14.5351 18.9091 20.7124C18.9091 25.945 16 28.5451 13.0909 29.4334C8.72727 30.7658 0 30.5962 0 19.2589Z"
                      fill="#2490EF"
                    ></path>
                    <path
                      d="M21.0898 19.2589C21.0898 7.92167 30.5444 1.69587 35.2717 0.00012207C29.4535 4.6513 29.2111 9.93233 29.8171 11.9915C35.9989 10.9013 39.9989 14.5351 39.9989 20.7124C39.9989 25.945 37.0898 28.5451 34.1808 29.4334C29.8171 30.7658 21.0898 30.5962 21.0898 19.2589Z"
                      fill="#2490EF"
                    ></path>
                  </g>
                </svg>
                <p>
                  Our experience with Exxon Info has been nothing short of
                  exceptional. The outsourcing candidates they brought to our
                  projects were not just knowledgeable; they seamlessly
                  integrated with our team, adding a global perspective. The
                  quality of work, attention to detail, and the collaborative
                  spirit of their candidates have significantly contributed to
                  our success. We're truly grateful for the partnership.
                </p>
              </div>
            </div>
            <div className="col-lg-4">
              <div className="Exxon-Oppening-Item">
                <svg
                  width="40"
                  height="30"
                  viewBox="0 0 40 30"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <g opacity="0.2">
                    <path
                      d="M0 19.2589C0 7.92167 9.45455 1.69587 14.1818 0.00012207C8.36364 4.6513 8.12121 9.93233 8.72727 11.9915C14.9091 10.9013 18.9091 14.5351 18.9091 20.7124C18.9091 25.945 16 28.5451 13.0909 29.4334C8.72727 30.7658 0 30.5962 0 19.2589Z"
                      fill="#2490EF"
                    ></path>
                    <path
                      d="M21.0898 19.2589C21.0898 7.92167 30.5444 1.69587 35.2717 0.00012207C29.4535 4.6513 29.2111 9.93233 29.8171 11.9915C35.9989 10.9013 39.9989 14.5351 39.9989 20.7124C39.9989 25.945 37.0898 28.5451 34.1808 29.4334C29.8171 30.7658 21.0898 30.5962 21.0898 19.2589Z"
                      fill="#2490EF"
                    ></path>
                  </g>
                </svg>
                <p>
                  Exxon Info has proven to be a standout outsourcing partner for
                  us. Their candidates not only met but exceeded our
                  expectations. Their diverse skill set, adaptability, and
                  cultural understanding have been invaluable. It's rare to find
                  a partner who understands the nuances of our industry and can
                  deliver with such precision. Exxon Info has become synonymous
                  with excellence in outsourcing in our books.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default WhyUs;

import React from "react";
import WhatsappLogo from "../../assets/ImagesWebp/Whatsapp.webp";
import "./Whatsapp.css";

const Whatsapp = () => {
  const handleClick = () => {
    window.location.href = "https://wa.me/919175277758";
  };
  return (
    <>
      <div className="whatsapp-button" onClick={handleClick}>
        <img src={WhatsappLogo} alt="WhatsApp Logo" className="whatsapp-logo" />
      </div>
    </>
  );
};

export default Whatsapp;

import { addDoc, collection } from "firebase/firestore/lite";
import React, { useState, useRef, useEffect } from "react";
import { IoCloseOutline } from "react-icons/io5";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/bootstrap.css";
import Swal from "sweetalert2";
import "sweetalert2/dist/sweetalert2.css";
import firebaseApp from "../firebase.config";

import { getFirestore } from "firebase/firestore/lite";
import { getFunctions, httpsCallable } from "firebase/functions";
import { useNavigate } from "react-router-dom";

const db = getFirestore(firebaseApp);
const functions = getFunctions(firebaseApp);

const PopUp = ({ isVisible, onClose }) => {
  const [engagementTimeline, setEngagementTimeline] = useState("");
  const [joining, setJoining] = useState("");
  const [experience, setExperience] = useState("");
  const [name, setName] = useState();
  const [phone, setPhone] = useState();
  const [email, setEmail] = useState();
  const [message, setMessage] = useState();
  const [loading, setLoading] = useState(false);

  const [isValidEmail, setIsValidEmail] = useState(true);

  const popUpRef = useRef(null);
  const navigate = useNavigate();

  const handleClickOutside = (e) => {
    if (popUpRef.current && !popUpRef.current.contains(e.target)) {
      onClose();
    }
  };

  useEffect(() => {
    document.body.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.body.removeEventListener("mousedown", handleClickOutside);
    };
  }, [onClose]);

  const handleEmailChange = (e) => {
    const enteredEmail = e.target.value;
    setEmail(enteredEmail);
    setIsValidEmail(validateEmail(enteredEmail));
  };

  const validateEmail = (email) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  };

  const showSweetAlert = () => {
    Swal.fire({
      title: "Success",
      text: "Thank you for getting in touch.",
      icon: "success",
      confirmButtonText: "OK",
    });
  };

  const handleFormSubmit = async (e) => {
    e.preventDefault();

    const requiredFields = [name, email, phone, message];

    if (requiredFields.some((field) => !field || field.trim() === "")) {
      alert("Please fill in all required fields");
      return;
    }

    if (!isValidEmail) {
      alert("Please enter a valid email address");
      return;
    }

    try {
      setLoading(true);
      functions.region = "asia-south1";
      functions.region = "asia-south1";
      const getEmailRes = httpsCallable(functions, "sendemail");
      const resp = await getEmailRes({
        name,
        phone,
        email,
        message,
        engagementTimeline,
        joining,
        experience,
      });
      if (
        resp &&
        resp?.data &&
        resp?.data?.message?.search("successfully") > -1
      ) {
        //showSweetAlert();
        navigate("/thankyou");
        onClose();

        const decRef = await addDoc(collection(db, "Exxondata"), {
          engagementTimeline,
          joining,
          exprience: experience,
          name,
          phone,
          email,
          message,
        });
        resetForm();
        setLoading(false);
      } else {
        console.error("Error");
        setLoading(false);
        resetForm();
      }
    } catch (error) {
      resetForm();
      console.error("Error adding document: ", error);
      setLoading(false);
    } finally {
      resetForm();
      setLoading(false);
    }

    function resetForm() {
      setEngagementTimeline("");
      setJoining("");
      setExperience("");
      setName("");
      setPhone("");
      setEmail("");
      setMessage("");
    }
  };

  return (
    <>
      {isVisible && (
        <div className="Exxon-popup-container" ref={popUpRef}>
          <div
            className="Popup-Header d-flex justify-content-end"
            onClick={onClose}
          >
            <IoCloseOutline className="Exxon-Close-Button " />
          </div>
          <h2>Request a Proposal</h2>
          <p>Assured response within 24 Hours.</p>

          <div className="Exxon-Engagement">
            <label className="Engagement-timeline">Engagement Timeline </label>
            <div>
              <div className="row">
                <div className="col-lg-4 radio-but">
                  <label className="radio-label">
                    <input
                      type="radio"
                      className="Exxon-Radio"
                      value="3 to 6 Months"
                      checked={engagementTimeline === "3 to 6 Months"}
                      onChange={() => setEngagementTimeline("3 to 6 Months")}
                    />
                    <span>3-6 Months</span>
                  </label>
                </div>
                <div className="col-lg-4 radio-but">
                  <label className="radio-label">
                    <input
                      type="radio"
                      className="Exxon-Radio"
                      value="6 Than 12 Months"
                      checked={engagementTimeline === "6 Than 12 Months"}
                      onChange={() => setEngagementTimeline("6 Than 12 Months")}
                    />
                    <span>6-12 Months</span>
                  </label>
                </div>
                <div className="col-lg-4 radio-but">
                  <label className="radio-label">
                    <input
                      type="radio"
                      className="Exxon-Radio"
                      value="More than 1 Year"
                      checked={engagementTimeline === "More than 1 Year"}
                      onChange={() => setEngagementTimeline("More than 1 Year")}
                    />
                    <span> More than 1 Year</span>
                  </label>
                </div>
              </div>
            </div>
          </div>

          <div className="Exxon-joining">
            <label className="Engagement-timeline">Joining</label>
            <i className="bi bi-x"></i>
            <i class="bi bi-x"></i>
            <div className="row">
              <div className="col-lg-4 radio-but">
                <label className="radio-label">
                  <input
                    type="radio"
                    className="Exxon-Radio"
                    value="immediately"
                    checked={joining === "immediately"}
                    onChange={() => setJoining("immediately")}
                  />
                  <span>Immediately</span>
                </label>
              </div>
              <div className="col-lg-4 radio-but">
                <label className="radio-label">
                  <input
                    type="radio"
                    className="Exxon-Radio"
                    value="15 Days"
                    checked={joining === "15 Days"}
                    onChange={() => setJoining("15 Days")}
                  />
                  <span>15 Days</span>
                </label>
              </div>

              <div className="col-lg-4 radio-but">
                <label className="radio-label">
                  <input
                    type="radio"
                    className="Exxon-Radio"
                    value="30 Days"
                    checked={joining === "30 Days"}
                    onChange={() => setJoining("30 Days")}
                  />
                  <span>30 Days</span>
                </label>
              </div>
            </div>
          </div>

          <div className="Exxon-Exprience">
            <label className="Engagement-timeline">Experience</label>
            <i className="bi bi-x"></i>
            <i className="bi bi-x"></i>
            <div className="row">
              <div className="col-lg-4 radio-but">
                <label className="radio-label">
                  <input
                    type="radio"
                    className="Exxon-Radio"
                    value="1 to 3 Years"
                    checked={experience === "1 to 3 Years"}
                    onChange={() => setExperience("1 to 3 Years")}
                  />
                  <span>1 to 3 Years</span>
                </label>
              </div>
              <div className="col-lg-4 radio-but">
                <label className="radio-label">
                  <input
                    type="radio"
                    className="Exxon-Radio"
                    value="4 to 7 Years"
                    checked={experience === "4 to 7 Years"}
                    onChange={() => setExperience("4 to 7 Years")}
                  />
                  <span>4 to 7 Years</span>
                </label>
              </div>
              <div className="col-lg-4 radio-but">
                <label className="radio-label">
                  <input
                    type="radio"
                    className="Exxon-Radio"
                    value="More than 7 Years"
                    checked={experience === "More than 7 Years"}
                    onChange={() => setExperience("More than 7 Years")}
                  />
                  <span>More than 7 Years</span>
                </label>
              </div>
            </div>
          </div>

          <div className="row Exxon-Contactus">
            <div className="col-md-12">
              <div className="form-group">
                <input
                  type="text"
                  className="medium-input"
                  id="firstName"
                  name="firstName"
                  placeholder="Name"
                  value={name}
                  onChange={(e) => setName(e.target.value)}
                ></input>
              </div>
            </div>

            <div className="col-md-12">
              <div className="form-group">
                <PhoneInput
                  country={"us"}
                  enableSearch={true}
                  type="tel"
                  inputProps={{
                    name: "phone",
                    placeholder: "Phone",
                    className: "phone-input",
                    id: "phone",
                  }}
                  value={phone}
                  onChange={(phone) => setPhone(phone)}
                ></PhoneInput>
              </div>
            </div>

            <div className="col-md-12">
              <div className="form-group">
                <input
                  type="email"
                  className={`medium-input ${isValidEmail ? "" : "invalid"}`}
                  id="email"
                  name="email"
                  placeholder="Email"
                  value={email}
                  onChange={handleEmailChange}
                ></input>
              </div>
              {!isValidEmail && (
                <p className="error-message">
                  Please enter a valid email address
                </p>
              )}
            </div>

            <div className="col-md-12">
              <div className="form-group">
                <textarea
                  className="medium-textarea h-100px bg-white"
                  name="message"
                  placeholder="Message"
                  value={message}
                  onChange={(e) => setMessage(e.target.value)}
                />
              </div>
            </div>

            <div>
              <button className="Exxon-Submit" onClick={handleFormSubmit}>
                {loading ? (
                  <div className="spinner-border text-dark" role="status">
                    <span className="sr-only"></span>
                  </div>
                ) : (
                  "Send Enquiry"
                )}
              </button>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default PopUp;
